import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Linkedin, Twitter, Github, ChevronLeft, ChevronRight } from 'lucide-react'

const TeamSection = styled.section`
  padding: 6rem 2rem;
  background-color: #f8fafc;
`

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #1e293b;
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
  font-weight: 700;

  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #4f46e5, #3b82f6);
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
  }
`

const TeamCarouselContainer = styled.div`
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
`

const TeamCarousel = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  padding: 1rem 0;
  
  &::-webkit-scrollbar {
    display: none;
  }

  ${props => props.isSingleMember && `
    justify-content: center;
    overflow-x: visible;
  `}
`

const TeamMember = styled.div`
  flex: 0 0 280px;
  scroll-snap-align: start;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin-right: 2rem;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  ${props => props.isSingleMember && `
    margin-right: 0;
  `}
`

const MemberImageContainer = styled.div`
  width: 120px;
  height: 120px;
  margin: 1.5rem auto;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #4f46e5;
`

const MemberImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const MemberInfo = styled.div`
  padding: 1.25rem;
  text-align: center;
`

const MemberName = styled.h3`
  font-size: 1.25rem;
  color: #1e293b;
  margin-bottom: 0.5rem;
`

const MemberRole = styled.p`
  font-size: 0.9rem;
  color: #4f46e5;
  font-weight: 600;
  margin-bottom: 0.75rem;
`

const MemberBio = styled.p`
  font-size: 0.85rem;
  color: #64748b;
  line-height: 1.5;
  margin-bottom: 0.75rem;
`

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.75rem;
`

const SocialLink = styled.a`
  color: #64748b;
  transition: color 0.3s ease;

  &:hover {
    color: #4f46e5;
  }
`

const CarouselButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(79, 70, 229, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;

  &:hover {
    background-color: rgba(79, 70, 229, 1);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const PrevButton = styled(CarouselButton)`
  left: -20px;
`

const NextButton = styled(CarouselButton)`
  right: -20px;
`

function TeamMemberCard({ name, role, image, bio, isSingleMember }) {
  return (
    <TeamMember isSingleMember={isSingleMember}>
      <MemberImageContainer>
        <MemberImage src={image} alt={name} />
      </MemberImageContainer>
      <MemberInfo>
        <MemberName>{name}</MemberName>
        <MemberRole>{role}</MemberRole>
        <MemberBio>{bio}</MemberBio>
        <SocialLinks>
          <SocialLink href="#" aria-label={`${name}'s LinkedIn`}>
            <Linkedin size={18} />
          </SocialLink>
          <SocialLink href="#" aria-label={`${name}'s Twitter`}>
            <Twitter size={18} />
          </SocialLink>
          <SocialLink href="#" aria-label={`${name}'s GitHub`}>
            <Github size={18} />
          </SocialLink>
        </SocialLinks>
      </MemberInfo>
    </TeamMember>
  )
}

function Team() {
  const carouselRef = useRef(null)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)

  const teamMembers = [
    {
      name: "Mohsin Mehmood",
      role: "CEO & Co-Founder",
      image: "/pic01.png",
      bio: "Visionary leader with 10+ years in tech innovation."
    }
  ]

  const isSingleMember = teamMembers.length === 1

  useEffect(() => {
    const carousel = carouselRef.current
    let resizeObserver

    const handleScroll = () => {
      if (carousel && !isSingleMember) {
        const { scrollLeft, scrollWidth, clientWidth } = carousel
        setCanScrollLeft(scrollLeft > 0)
        setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 1)
      }
    }

    const handleResize = () => {
      handleScroll()
    }

    if (carousel && !isSingleMember) {
      carousel.addEventListener('scroll', handleScroll)
      handleScroll()

      resizeObserver = new ResizeObserver(handleResize)
      resizeObserver.observe(carousel)
    }

    return () => {
      if (carousel && !isSingleMember) {
        carousel.removeEventListener('scroll', handleScroll)
      }
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [isSingleMember])

  const scroll = (direction) => {
    if (carouselRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' })
    }
  }

  return (
    <TeamSection>
      <SectionTitle>Our Team</SectionTitle>
      <TeamCarouselContainer>
        {!isSingleMember && (
          <PrevButton onClick={() => scroll('left')} disabled={!canScrollLeft}>
            <ChevronLeft size={24} />
          </PrevButton>
        )}
        <TeamCarousel ref={carouselRef} isSingleMember={isSingleMember}>
          {teamMembers.map((member, index) => (
            <TeamMemberCard key={index} {...member} isSingleMember={isSingleMember} />
          ))}
        </TeamCarousel>
        {!isSingleMember && (
          <NextButton onClick={() => scroll('right')} disabled={!canScrollRight}>
            <ChevronRight size={24} />
          </NextButton>
        )}
      </TeamCarouselContainer>
    </TeamSection>
  )
}

export default Team