'use client'

import React, { useState, useEffect } from 'react'
import styled, { keyframes, createGlobalStyle } from 'styled-components'
import { ArrowDown, Rocket, Target, Trophy } from 'lucide-react'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');

  body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`

const HomeWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
`

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`

const HeroSection = styled.div`
  background: linear-gradient(135deg, rgba(67, 56, 202, 0.8), rgba(134, 239, 172, 0.8));
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`

const fadeInScale = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

const HeroContent = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  padding: 3rem 4rem;
  width: 100%;
  max-width: 1000px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  animation: ${fadeInScale} 0.8s ease-out forwards;
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 300px;
    height: 300px;
    background: linear-gradient(135deg, rgba(67, 56, 202, 0.1), rgba(134, 239, 172, 0.1));
    border-radius: 50%;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.8s ease-out, transform 1.2s ease-out;
  }

  &::before {
    top: -150px;
    left: -150px;
  }

  &::after {
    bottom: -150px;
    right: -150px;
  }

  &:hover::before,
  &:hover::after {
    opacity: 1;
    transform: scale(1.2);
  }
`

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const HeroTitle = styled.h1`
  font-size: 2.75rem;
  color: #1e293b;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 700;
  line-height: 1.2;
  animation: ${fadeInUp} 0.8s ease-out 0.2s forwards;
  opacity: 0;
`

const HeroSubtitle = styled.p`
  font-size: 1.25rem;
  color: #4b5563;
  margin-bottom: 2.5rem;
  text-align: center;
  max-width: 800px;
  line-height: 1.6;
  animation: ${fadeInUp} 0.8s ease-out 0.4s forwards;
  opacity: 0;
`

const CTAButton = styled.button`
  background-color: #4f46e5;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.3s ease;
  animation: ${fadeInUp} 0.8s ease-out 0.6s forwards;
  opacity: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(79, 70, 229, 0.2);
  }

  &:active {
    transform: translateY(0);
  }
`

const Section = styled.section`
  padding: 6rem 2rem;
  background-color: #f8fafc;
`

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #1e293b;
  text-align: center;
  margin-bottom: 3rem;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #4f46e5, #3b82f6);
    margin: 1rem auto 0;
    border-radius: 2px;
  }
`

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1000px;
  margin: 0 auto;
`

const FeatureCard = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
  opacity: 0;
  transform: translateY(30px);

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`

const FeatureIcon = styled.div`
  font-size: 2.5rem;
  margin-bottom: 1.25rem;
  color: #4f46e5;
`

const FeatureTitle = styled.h3`
  font-size: 1.25rem;
  color: #1e293b;
  margin-bottom: 0.75rem;
  font-weight: 600;
`

const FeatureDescription = styled.p`
  color: #4b5563;
  font-size: 0.9rem;
  line-height: 1.6;
`

function Home() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const servicesSection = document.getElementById('services')
      if (servicesSection) {
        const rect = servicesSection.getBoundingClientRect()
        setIsVisible(rect.top <= window.innerHeight * 0.75)
      }
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const features = [
    { 
      icon: <Rocket size={36} />, 
      title: 'Cutting-Edge Expertise', 
      description: 'Our team stays at the forefront of technological advancements to deliver state-of-the-art solutions that give your business a competitive edge.' 
    },
    { 
      icon: <Target size={36} />, 
      title: 'Tailored to Your Needs', 
      description: 'We take the time to understand your unique business challenges and goals, crafting customized solutions that drive your success.' 
    },
    { 
      icon: <Trophy size={36} />, 
      title: 'Proven Track Record', 
      description: 'With numerous successful projects and satisfied clients across industries, HighDevs has established itself as a reliable and trusted technology partner.' 
    },
  ]

  const handleCTAClick = () => {
    const servicesSection = document.getElementById('services')
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <HomeWrapper>
      <GlobalStyle />
      <HeroSection>
        <HeroContent>
          <HeroTitle>
            Empower Your Business with Innovative Technology Solutions
          </HeroTitle>
          <HeroSubtitle>
            Your Trusted Partner for AI, Web, and Mobile App Development 
          </HeroSubtitle>
          <CTAButton onClick={handleCTAClick}>
            Discover Our Services
            <ArrowDown size={18} />
          </CTAButton>
        </HeroContent>
      </HeroSection>

      <Section id="services">
        <SectionTitle className={isVisible ? 'visible' : ''}>
          Why Choose Us
        </SectionTitle>
        <FeaturesGrid>
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              className={isVisible ? 'visible' : ''}
              style={{ transitionDelay: `${index * 0.2}s` }}
            >
              <FeatureIcon>{feature.icon}</FeatureIcon>
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureDescription>{feature.description}</FeatureDescription>
            </FeatureCard>
          ))}
        </FeaturesGrid>
      </Section>
    </HomeWrapper>
  )
}

export default Home