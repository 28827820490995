'use client'

import React, { useState, useEffect } from 'react'
import styled, { keyframes, css, createGlobalStyle } from 'styled-components'
import { ChevronRight, Code, Smartphone, Brain, MessageSquare, Eye, Layers, Server, Cpu } from 'lucide-react'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');
  
  body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f8fafc;
    color: #1e293b;
  }
`

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`

const fadeInUp = keyframes`
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
`

const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 6rem 2rem;
  overflow: hidden;
`

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #1e293b;
  text-align: center;
  margin-bottom: 4rem;
  position: relative;
  font-weight: 700;

  &:after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #4f46e5, #3b82f6);
    border-radius: 2px;
  }

  animation: ${fadeInUp} 1s ease-out forwards;
`

const ServiceCategory = styled.div`
  margin-bottom: 5rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;

  ${props => props.isVisible && css`
    opacity: 1;
    transform: translateY(0);
  `}
`

const CategoryTitle = styled.h3`
  font-size: 2rem;
  color: #1e293b;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
`

const CategoryDescription = styled.p`
  font-size: 1rem;
  color: #4b5563;
  margin-bottom: 3rem;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
`

const ServiceCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`

const ServiceIcon = styled.div`
  font-size: 2rem;
  color: #4f46e5;
  margin-bottom: 1.25rem;
`

const ServiceTitle = styled.h4`
  font-size: 1.25rem;
  color: #1e293b;
  margin-bottom: 0.75rem;
  font-weight: 600;
  transition: color 0.3s ease;

  ${ServiceCard}:hover & {
    color: #4f46e5;
  }
`

const ServiceDescription = styled.p`
  font-size: 0.9rem;
  color: #4b5563;
  line-height: 1.6;
`

const ContactCTA = styled.div`
  text-align: center;
  margin-top: 5rem;
  padding: 3rem;
  background: linear-gradient(-45deg, #4f46e5, #3b82f6, #06b6d4, #10b981);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  animation: ${fadeInUp} 1s ease-out forwards, ${gradientAnimation} 15s ease infinite;
  animation-delay: 0.5s;
  opacity: 0;

  p {
    color: white;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    line-height: 1.6;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
`

const CTAButton = styled.button`
  background: white;
  color: #4f46e5;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    background: #4f46e5;
    color: white;
  }

  &:active {
    transform: translateY(-1px);
  }
`

function Services() {
  const [visibleCategories, setVisibleCategories] = useState([])

  useEffect(() => {
    const handleScroll = () => {
      const categories = document.querySelectorAll('.service-category')
      categories.forEach((category, index) => {
        const rect = category.getBoundingClientRect()
        if (rect.top <= window.innerHeight * 0.75 && !visibleCategories.includes(index)) {
          setVisibleCategories(prev => [...prev, index])
        }
      })
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => window.removeEventListener('scroll', handleScroll)
  }, [visibleCategories])

  const services = {
    ai: {
      title: "AI Solutions",
      description: "Harness the power of artificial intelligence to gain valuable insights, automate processes, and make data-driven decisions. Our AI solutions include:",
      offerings: [
        {
          icon: <Brain size={32} />,
          title: "Machine Learning",
          description: "Leverage custom machine learning models to uncover patterns, make predictions, and optimize business operations."
        },
        {
          icon: <MessageSquare size={32} />,
          title: "Natural Language Processing",
          description: "Unlock the potential of unstructured text data with our advanced NLP solutions for sentiment analysis and more."
        },
        {
          icon: <Eye size={32} />,
          title: "Computer Vision",
          description: "Automate visual tasks and gain valuable insights from images and videos with our computer vision solutions."
        }
      ]
    },
    web: {
      title: "Web Development",
      description: "Build powerful, interactive web experiences that engage users and drive results. Our web development services cover the full spectrum:",
      offerings: [
        {
          icon: <Layers size={32} />,
          title: "Front-End Development",
          description: "Create stunning, responsive user interfaces with modern frameworks like React, Vue.js, and Angular."
        },
        {
          icon: <Server size={32} />,
          title: "Back-End Development",
          description: "Develop robust, scalable server-side solutions using technologies like Node.js, Python, and Java."
        },
        {
          icon: <Code size={32} />,
          title: "Full-Stack Development",
          description: "From concept to deployment, our full-stack development team handles every aspect of your web project."
        }
      ]
    },
    mobile: {
      title: "Mobile App Development",
      description: "Engage your audience on the go with intuitive, feature-rich mobile applications. Our mobile app development services include:",
      offerings: [
        {
          icon: <Smartphone size={32} />,
          title: "iOS App Development",
          description: "Build native iOS apps that leverage the full potential of the Apple ecosystem using Swift and Objective-C."
        },
        {
          icon: <Smartphone size={32} />,
          title: "Android App Development",
          description: "Reach the vast Android user base with custom-built native apps using Kotlin and Java."
        },
        {
          icon: <Cpu size={32} />,
          title: "Cross-Platform Development",
          description: "Efficiently build apps for both iOS and Android with a single codebase using React Native and Flutter."
        }
      ]
    }
  }

  return (
    <ServicesWrapper>
      <GlobalStyle />
      <SectionTitle>Our Services</SectionTitle>
      {Object.values(services).map((category, index) => (
        <ServiceCategory 
          key={index} 
          className="service-category"
          isVisible={visibleCategories.includes(index)}
        >
          <CategoryTitle>{category.title}</CategoryTitle>
          <CategoryDescription>{category.description}</CategoryDescription>
          <ServiceGrid>
            {category.offerings.map((service, serviceIndex) => (
              <ServiceCard key={serviceIndex}>
                <ServiceIcon>{service.icon}</ServiceIcon>
                <ServiceTitle>{service.title}</ServiceTitle>
                <ServiceDescription>{service.description}</ServiceDescription>
              </ServiceCard>
            ))}
          </ServiceGrid>
        </ServiceCategory>
      ))}
      <ContactCTA>
        <p>At HighDevs, we are committed to delivering cutting-edge technology solutions that drive your business forward. Let's create something amazing together.</p>
        <CTAButton>
          Get Started
          <ChevronRight size={18} />
        </CTAButton>
      </ContactCTA>
    </ServicesWrapper>
  )
}

export default Services