import React from 'react'
import styled from 'styled-components'
import { Linkedin, Twitter, Github, Mail, Phone, MapPin } from 'lucide-react'

const FooterWrapper = styled.footer`
  background: linear-gradient(135deg, rgba(67, 56, 202, 0.8), rgba(134, 239, 172, 0.8));
  color: #ffffff;
  padding: 4rem 2rem;
`

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
`

const FooterSection = styled.div`
  text-align: left;
`

const FooterTitle = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
  position: relative;
  padding-bottom: 0.5rem;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40px;
    height: 2px;
    background-color: #ffffff;
  }
`

const FooterList = styled.ul`
  list-style: none;
  padding: 0;
`

const FooterListItem = styled.li`
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
`

const FooterLink = styled.a`
  color: #ffffff;
  text-decoration: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    color: #bfdbfe;
    transform: translateX(5px);
  }
`

const FooterText = styled.p`
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`

const SocialLink = styled.a`
  color: #ffffff;
  transition: all 0.3s ease;

  &:hover {
    color: #bfdbfe;
    transform: translateY(-3px);
  }
`

const Copyright = styled.p`
  text-align: center;
  margin-top: 3rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.9rem;
`

function Footer() {
  return (
    <FooterWrapper>
      <FooterContent>
        <FooterSection>
          <FooterTitle>HighDevs</FooterTitle>
          <FooterText>
            Specializing in AI, Web Development, and Mobile Application Development. We turn your ideas into reality.
          </FooterText>
          <SocialLinks>
            <SocialLink href="#" aria-label="LinkedIn">
              <Linkedin size={20} />
            </SocialLink>
            <SocialLink href="#" aria-label="Twitter">
              <Twitter size={20} />
            </SocialLink>
            <SocialLink href="#" aria-label="GitHub">
              <Github size={20} />
            </SocialLink>
          </SocialLinks>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Services</FooterTitle>
          <FooterList>
            <FooterListItem>
              <FooterLink href="#ai">AI Solutions</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink href="#web">Web Development</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink href="#mobile">Mobile App Development</FooterLink>
            </FooterListItem>
          </FooterList>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Contact</FooterTitle>
          <FooterList>
            <FooterListItem>
              <FooterLink href="mailto:info@highdevs.com">
                <Mail size={16} />
                info@highdevs.com
              </FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink href="tel:+15551234567">
                <Phone size={16} />
                +1 (555) 123-4567
              </FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink href="#" aria-label="Address">
                <MapPin size={16} />
                123 Tech Street, Silicon Valley, CA
              </FooterLink>
            </FooterListItem>
          </FooterList>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Quick Links</FooterTitle>
          <FooterList>
            <FooterListItem>
              <FooterLink href="#about">About Us</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink href="#projects">Our Projects</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink href="#careers">Careers</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink href="#privacy">Privacy Policy</FooterLink>
            </FooterListItem>
          </FooterList>
        </FooterSection>
      </FooterContent>
      <Copyright>
        &copy; {new Date().getFullYear()} HighDevs pvt. ltd. All rights reserved.
      </Copyright>
    </FooterWrapper>
  )
}

export default Footer