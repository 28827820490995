'use client'

import React, { useState } from 'react'
import styled, { keyframes, createGlobalStyle } from 'styled-components'
import { Send, MapPin, Phone, Mail, Clock } from 'lucide-react'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');
  
  body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`

const fadeInUp = keyframes`
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
`

const ContactWrapper = styled.div`
  max-width: 100%;
  overflow-x: hidden;
`

const HeroSection = styled.div`
  background: linear-gradient(-45deg, #4f46e5, #3b82f6, #06b6d4, #10b981);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  color: white;
  padding: 6rem 2rem;
  text-align: center;
`

const HeroTitle = styled.h1`
  font-size: 2.75rem;
  margin-bottom: 1rem;
  font-weight: 700;
  animation: ${fadeInUp} 0.8s ease-out;
`

const HeroSubtitle = styled.p`
  font-size: 1.25rem;
  max-width: 800px;
  margin: 0 auto;
  opacity: 0.9;
  animation: ${fadeInUp} 0.8s ease-out 0.2s forwards;
`

const ContactSection = styled.section`
  padding: 5rem 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 4rem;
  flex-wrap: wrap;
  background-color: #f8fafc;
`

const ContactForm = styled.form`
  width: 100%;
  max-width: 500px;
  background-color: white;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: ${fadeInUp} 0.8s ease-out 0.4s forwards;
  opacity: 0;
`

const FormTitle = styled.h2`
  font-size: 2rem;
  color: #1e293b;
  margin-bottom: 1.5rem;
  font-weight: 600;
`

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #4b5563;
  font-weight: 500;
`

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    border-color: #4f46e5;
    outline: none;
    box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
  }
`

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 1rem;
  resize: vertical;
  min-height: 150px;
  transition: all 0.3s ease;

  &:focus {
    border-color: #4f46e5;
    outline: none;
    box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
  }
`

const SubmitButton = styled.button`
  background-color: #4f46e5;
  color: white;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: #4338ca;
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(79, 70, 229, 0.3);
  }

  &:active {
    transform: translateY(-1px);
  }
`

const ContactInfo = styled.div`
  width: 100%;
  max-width: 400px;
  animation: ${fadeInUp} 0.8s ease-out 0.6s forwards;
  opacity: 0;
`

const ContactInfoTitle = styled.h2`
  font-size: 2rem;
  color: #1e293b;
  margin-bottom: 1.5rem;
  font-weight: 600;
`

const ContactItem = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`

const ContactItemContent = styled.div``

const ContactItemTitle = styled.h3`
  font-size: 1.2rem;
  color: #1e293b;
  margin-bottom: 0.25rem;
  font-weight: 600;
`

const ContactItemText = styled.p`
  font-size: 1rem;
  color: #4b5563;
  line-height: 1.5;
`

const SuccessMessage = styled.div`
  background-color: #10b981;
  color: white;
  padding: 1rem;
  border-radius: 6px;
  margin-top: 1rem;
  text-align: center;
  font-weight: 500;
  animation: ${fadeInUp} 0.5s ease-out;
`

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  })
  const [submitMessage, setSubmitMessage] = useState(null)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Here you would typically send the form data to a server
    console.log('Form submitted:', formData)
    setSubmitMessage('Thank you for your message! We will get back to you soon.')
    setFormData({ name: '', email: '', message: '' })
  }

  return (
    <ContactWrapper>
      <GlobalStyle />
      <HeroSection>
        <HeroTitle>Contact Us</HeroTitle>
        <HeroSubtitle>Get in touch with our team of experts</HeroSubtitle>
      </HeroSection>

      <ContactSection>
        <ContactForm onSubmit={handleSubmit}>
          <FormTitle>Send us a message</FormTitle>
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="message">Message</Label>
            <TextArea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <SubmitButton type="submit">
            Send Message
            <Send size={18} />
          </SubmitButton>
          {submitMessage && <SuccessMessage>{submitMessage}</SuccessMessage>}
        </ContactForm>

        <ContactInfo>
          <ContactInfoTitle>Contact Information</ContactInfoTitle>
          <ContactItem>
            <MapPin size={24} color="#4f46e5" />
            <ContactItemContent>
              <ContactItemTitle>Address</ContactItemTitle>
              <ContactItemText>NewCity Phase 2, Wahcantt</ContactItemText>
            </ContactItemContent>
          </ContactItem>
          <ContactItem>
            <Phone size={24} color="#4f46e5" />
            <ContactItemContent>
              <ContactItemTitle>Phone</ContactItemTitle>
              <ContactItemText>+92 304-5146995</ContactItemText>
            </ContactItemContent>
          </ContactItem>
          <ContactItem>
            <Mail size={24} color="#4f46e5" />
            <ContactItemContent>
              <ContactItemTitle>Email</ContactItemTitle>
              <ContactItemText>info@highdevs.com</ContactItemText>
            </ContactItemContent>
          </ContactItem>
          <ContactItem>
            <Clock size={24} color="#4f46e5" />
            <ContactItemContent>
              <ContactItemTitle>Working Hours</ContactItemTitle>
              <ContactItemText>Monday - Friday: 9 AM - 6 PM</ContactItemText>
            </ContactItemContent>
          </ContactItem>
        </ContactInfo>
      </ContactSection>
    </ContactWrapper>
  )
}

export default Contact