import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

const HeaderWrapper = styled.header`
  background-color: ${props => props.scrolled ? 'rgba(255, 255, 255, 0.9)' : 'transparent'};
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  box-shadow: ${props => props.scrolled ? '0 2px 10px rgba(0, 0, 0, 0.1)' : 'none'};
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const Logo = styled.a`
  background: ${props => props.scrolled ? 'linear-gradient(135deg, #6e8efb, #a777e3)' : 'transparent'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${props => props.scrolled ? 'transparent' : '#2c3e50'};
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 700;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 1.5rem;
`;

const NavItem = styled(Link)`
  color: ${props => props.scrolled ? '#2c3e50' : '#ffffff'};
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.scrolled ? 'rgba(110, 142, 251, 0.1)' : 'rgba(255, 255, 255, 0.1)'};
    transform: translateY(-2px);
  }

  &.active {
    background: ${props => props.scrolled ? 'linear-gradient(135deg, #6e8efb, #a777e3)' : 'rgba(255, 255, 255, 0.2)'};
    color: ${props => props.scrolled ? '#ffffff' : '#ffffff'};
  }
`;

function Header() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <HeaderWrapper scrolled={scrolled}>
      <HeaderContent>
        <Logo href="#home" scrolled={scrolled}>HighDevs</Logo>
        <Nav>
          <NavItem to="home" spy={true} smooth={true} offset={-80} duration={500} scrolled={scrolled}>Home</NavItem>
          <NavItem to="about" spy={true} smooth={true} offset={-80} duration={500} scrolled={scrolled}>About</NavItem>
          <NavItem to="services" spy={true} smooth={true} offset={-80} duration={500} scrolled={scrolled}>Services</NavItem>
          <NavItem to="contact" spy={true} smooth={true} offset={-80} duration={500} scrolled={scrolled}>Contact</NavItem>
        </Nav>
      </HeaderContent>
    </HeaderWrapper>
  );
}

export default Header;